<template>
  <ConfirmDialog
    maxWidth="850px"
    :value.sync="showPopup"
    positiveButtonText="Zapisz"
    :persistent="true"
    :dontHideOnPositiveAction="true"
    :disabledPositiveButton="uploading"
    :disabledNegativeButton="uploading"
    :positiveAction="saveEditedImage"
    :negativeAction="cancel"
    negativeButtonColor="primary"
    title="Edytuj plik"
    :compact="false"
    showCloseBtn
  >
    <v-layout align-center class="header-toolbar" slot="header">
      <v-layout class="ml-4 mr-6 header-toolbar-control">
        <v-flex class="mr-2 header-toolbar-text" shrink>
          <div class="text-body-1 label-text">Nazwa pliku</div>
          <div class="text-body-1 value-text">{{ value.title }}</div>
        </v-flex>
        <v-flex class="ml-2" shrink>
          <div class="text-body-1 label-text">Rozmiar</div>
          <div class="text-body-1 value-text">{{ value.human_size }}</div>
        </v-flex>
        <v-divider class="header-divider mx-2" vertical> </v-divider>
        <v-flex class="edit-buttons">
          <DefaultButton @click.prevent="rotateMinusImg">
            <v-icon small>mdi-undo</v-icon>
          </DefaultButton>
          <DefaultButton @click.prevent="rotatePlusImg">
            <v-icon small>mdi-redo</v-icon>
          </DefaultButton>
          <DefaultButton
            :class="[cropMode ? 'active-crop-btn' : '']"
            @click.prevent="cropImage"
            ><v-icon small>mdi-crop</v-icon>
          </DefaultButton>
        </v-flex>
        <v-flex shrink v-if="cropMode">
          <DefaultButton bgColor="error" @click.prevent="cancelCroppingImage"
            >Anuluj</DefaultButton
          >
          <DefaultButton primary @click.prevent="saveCroppingImage">Zapisz</DefaultButton>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout class="justify-center" slot="content">
      <vue-cropper
        ref="cropper"
        :src="value.image"
        :autoCrop="false"
        @ready="initCrop"
        :zoomable="false"
      >
      </vue-cropper>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
export default {
  data() {
    return {
      cropMode: false,
      image: "",
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    VueCropper: () => import("vue-cropperjs"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
  },
  props: {
    value: {},
    showPopup: {
      type: Boolean,
      default: false,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    initCrop() {
      this.$refs.cropper.reset();
      this.$refs.cropper.disable();
    },
    saveEditedImage() {
      if (this.image != "") {
        this.value.image = this.image;
      }
      this.$emit("saveEditedImage", this.value);
    },
    cropImage() {
      this.$refs.cropper.enable();
      this.$refs.cropper.initCrop();
      this.cropMode = true;
    },
    updateImage() {
      this.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$refs.cropper.replace(this.image);
    },
    saveCroppingImage() {
      this.updateImage();
      this.$refs.cropper.disable();
      this.$refs.cropper.clear();
      this.cropMode = false;
    },
    cancelCroppingImage() {
      this.$refs.cropper.clear();
      this.$refs.cropper.disable();
      this.cropMode = false;
    },
    cancel() {
      this.cropMode = false;
      this.$emit("cancel");
    },
    rotatePlusImg() {
      this.$refs.cropper.enable();
      if (this.cropMode == true) {
        this.$refs.cropper.clear();
        this.$refs.cropper.rotate(90);
        this.updateImage();
        this.$refs.cropper.disable();
        setTimeout(() => {
          this.cropImage();
        }, 100);
      }
      if (this.cropMode == false) {
        this.$refs.cropper.rotate(90);
        this.updateImage();
      }
    },
    rotateMinusImg() {
      this.$refs.cropper.enable();
      if (this.cropMode == true) {
        this.$refs.cropper.clear();
        this.$refs.cropper.rotate(-90);
        this.updateImage();
        this.$refs.cropper.disable();
        setTimeout(() => {
          this.cropImage();
        }, 100);
      }
      if (this.cropMode == false) {
        this.$refs.cropper.rotate(-90);
        this.updateImage();
      }
    },
  },
};
</script>

<style scoped class="css">
.header-toolbar {
  width: 100%;
  background-color: #f9f9f9;
}
.header-toolbar-control {
  margin: 10px;
  align-items: center;
  align-content: center;
  min-height: 45px;
}
.edit-buttons .v-btn {
  min-width: 20px;
  width: 36px;
  background-color: white !important;
}
.active-crop-btn {
  border: 2px solid black !important;
  color: black !important;
}
.value-text {
  max-width: 300px;
  overflow: hidden;
  color: black;
  text-overflow: ellipsis;
}
.label-text {
  color: #cfd2d8;
}
.header-style {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.description-style {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.image-style {
  background-color: #f6f8fc;
  cursor: pointer;
}
@media screen and (max-width: 900px) {
  .header-toolbar {
    display: contents;
  }
  .header-toolbar-text {
    margin: 0 !important;
  }
  .header-toolbar-control {
    height: 100%;
    display: contents;
  }
  .header-divider {
    display: none;
  }
  .label-text {
    text-align: center;
  }
  .value-text {
    text-align: center;
  }
}
</style>
